/* eslint-disable max-len */
import React from 'react';
import { useEffect } from 'react';
import Layout from '../../components/layout/Layout';

const TermsOfUse = () => {
    return (
        <Layout pageTitle={'Terms of Use'}>
            <section style={{ width: "100%", display: "flex", justifyContent: "center", marginBlock: "1rem 4rem" }}>
                <div style={{ width: "90%" }}
                    // Using https://wordtohtml.net/ to simplify policy updates
                    dangerouslySetInnerHTML={{__html: `
                <h1 style='margin-top:0in;margin-right:0in;margin-bottom:7.5pt;margin-left:0in;line-height:normal;font-family:"Times New Roman",serif;text-align:justify;background:white;'><u>TERMS OF USE</u></h1>
                <p style='margin-top:0in;margin-right:0in;margin-bottom:7.5pt;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;background:white;'><em><span style='font-family:"Times New Roman",serif;color:black;'>&nbsp;</span></em></p>
                <p style='margin-top:0in;margin-right:0in;margin-bottom:7.5pt;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;background:white;'><em><span style='font-family:"Times New Roman",serif;color:black;'>Last Update: September 28, 2022</span></em></p>
                <p style='margin-top:0in;margin-right:0in;margin-bottom:7.5pt;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;background:white;'><span style='font-family:"Times New Roman",serif;color:#2A2A2A;'>Tivity Health</span><sup><span style='font-family:"Times New Roman",serif;color:black;'>&reg;</span></sup><span style='font-family:"Times New Roman",serif;color:#2A2A2A;'>, Inc. (&ldquo;Tivity&rdquo;) owns and operates&nbsp;</span><span style='font-family:"Times New Roman",serif;color:#232226;'>operate websites, online platforms, mobile applications and other services that link to this Terms of Use (the &ldquo;<u>Services</u>&rdquo;)</span><span style='font-family:"Times New Roman",serif;color:black;'>. Tivity and its subsidiaries are referred to as &ldquo;We,&rdquo; &ldquo;Us,&rdquo; or &ldquo;Our.&rdquo; &ldquo;You&rdquo; or &ldquo;Your&rdquo; refers to any visitor or user of the Services.&nbsp;</span><strong><span style='font-family:"Times New Roman",serif;color:black;'>PLEASE READ THESE TERMS AND CONDITIONS OF USE CAREFULLY BEFORE USING THE SERVICES.</span></strong></p>
                <p style='margin-top:0in;margin-right:0in;margin-bottom:12.75pt;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;background:white;'><span style='font-family:"Times New Roman",serif;color:black;'>By accessing or using the Services, You&nbsp;</span><span style='font-family:"Times New Roman",serif;color:black;'>represent and warrant that You are at least eighteen (18) years old and possess the legal right and ability to agree to the following Terms of Use, and You agree to and are bound by the terms, conditions, policies and notices contained on this page (these &ldquo;Terms&rdquo;)</span><span style='font-family:"Times New Roman",serif;color:black;'>. If You do not agree to all of the terms and conditions of these Terms, do not use the Services!&nbsp;</span><span style='font-family:"Times New Roman",serif;color:black;'>From time to time, We may revise these Terms. Any revisions will be effective when We post the revised Terms on the Services. You should regularly review these Terms whenever You enter the Services.&nbsp;If You object to any provision of these Terms or any subsequent modifications, Your only recourse is to immediately terminate use of the Services.&nbsp;</span></p>
                <p style='margin-top:0in;margin-right:0in;margin-bottom:12.75pt;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;background:white;'><span style='font-family:"Times New Roman",serif;color:black;'>THESE TERMS INCLUDE A MANDATORY ARBITRATION AGREEMENT, WHICH MEANS THAT YOU AGREE TO SUBMIT ANY DISPUTE RELATED TO THE SITE TO BINDING INDIVIDUAL ARBITRATION RATHER THAN PROCEEDING IN COURT. THE ARBITRATION PROVISION ALSO INCLUDES A CLASS ACTION WAIVER, WHICH MEANS THAT YOU AGREE TO PROCEED WITH ANY DISPUTE INDIVIDUALLY AND NOT AS PART OF A CLASS ACTION, AND A JURY WAIVER.</span></p>
                <p style='margin:0in;font-size:16px;font-family:"Times New Roman",serif;text-align:justify;'><span style="font-size:15px;color:black;"><span style="text-decoration:none;">&nbsp;</span></span></p>
                <p style='margin:0in;font-size:16px;font-family:"Times New Roman",serif;text-align:justify;'><strong><u>TERMS OF USE</u></strong></p>
                <p style='margin-top:6.0pt;margin-right:0in;margin-bottom:0in;margin-left:0in;background:white;font-size:11px;font-family:"Times New Roman",serif;'><span style="font-size:15px;">&nbsp;</span></p>
                <div style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;'>
                    <ol start="1" style="margin-bottom:0in;list-style-type: decimal; padding: 0 1rem;">
                        <li style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;'><span style='font-family:"Times New Roman";font-size:15px;'>USER SUBMISSIONS AND TESTIMONIALS</span></li>
                    </ol>
                </div>
                <p style='margin:0in;font-size:16px;font-family:"Times New Roman",serif;margin-left:4.5pt;text-align:justify;'><span style="font-size:15px;">&nbsp;</span></p>
                <p style='margin:0in;font-size:16px;font-family:"Times New Roman",serif;text-align:justify;'><span style="font-size:15px;">You may be provided an opportunity to submit content or feedback, including testimonials (&ldquo;Your Input&rdquo;) to Us via the Services. Your Input may be publicly shared; do not provide Us with Your Input if You consider it confidential or do not want that information shared publicly. By submitting Your Input to Us You (a) grant Us all right, title and interest, including all intellectual property rights, in and to Your Input; (b) certify that any person pictured in the Your Input (or, if a minor, his/her parent/legal guardian) authorizes Tivity to use, copy, print, display, reproduce, modify, publish, post, transmit and distribute any material included with Your Input; and (c) You agree to indemnify Tivity and its affiliates, directors, officers and employees and hold them harmless from any and all claims and expenses, including attorneys&rsquo; fees, arising from the media and/or Your failure to comply with these the terms described in these Terms. In addition, the Services include opportunities to publicly comment through third party social media services, i.e. Twitter or Facebook. If You provide information in connection with these services, Your username may be required and may be made publicly available.&nbsp;</span></p>
                <p style='margin:0in;font-size:16px;font-family:"Times New Roman",serif;text-align:justify;'><span style="font-size:15px;">&nbsp;</span></p>
                <div style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;'>
                    <ol start="2" style="margin-bottom:0in; padding: 0 1rem;">
                        <li style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;'><span style='font-family:"Times New Roman";font-size:15px;'>YOUR USE OF THE SERVICES</span></li>
                    </ol>
                </div>
                <p style='margin:0in;font-size:16px;font-family:"Times New Roman",serif;margin-left:4.5pt;text-align:justify;'><span style="font-size:15px;">&nbsp;</span></p>
                <p style='margin:0in;font-size:16px;font-family:"Times New Roman",serif;text-align:justify;'><span style="font-size:15px;">You are responsible for Your activity on the Services and for any content You submit to the Services. Any fraudulent, abusive, or other illegal activity may result in termination of Your account at Our sole discretion. We may refer Your conduct to appropriate law enforcement agencies. You agree that You will not allow any other person or entity to use Your access credentials to access the Services or take any other action that interferes with other parties&rsquo; use of the Services.</span></p>
                <p style='margin:0in;font-size:16px;font-family:"Times New Roman",serif;text-align:justify;'><span style="font-size:15px;">&nbsp;</span></p>
                <p style='margin:0in;font-size:16px;font-family:"Times New Roman",serif;text-align:justify;'><span style="font-size:15px;">The Services include capabilities for You to submit information to contact Us. To the extent you provide any information, including personal information, to Us, you represent and warrant that (i) you are providing or accessing only your own information or the information of others which you are authorized to provide to third parties; and (ii) the use of such information by Us will not infringe upon or misappropriate the intellectual property rights, including rights of privacy or otherwise violate the rights of any third parties, or violate any applicable law, rule or regulation. You are solely responsible for and hereby warrant that the information you submit is accurate and does not contain any misrepresentations or fraudulent information.</span></p>
                <p style='margin:0in;font-size:16px;font-family:"Times New Roman",serif;text-align:justify;'><span style="font-size:15px;">&nbsp;</span></p>
                <div style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;'>
                    <ol start="3" style="margin-bottom:0in; padding: 0 1rem;">
                        <li style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;'><span style='font-family:"Times New Roman";font-size:15px;'>THIRD PARTY SITES</span></li>
                    </ol>
                </div>
                <p style='margin:0in;font-size:16px;font-family:"Times New Roman",serif;text-align:justify;'><span style="font-size:15px;">&nbsp;</span></p>
                <p style='margin:0in;font-size:16px;font-family:"Times New Roman",serif;text-align:justify;'><span style="font-size:15px;">We may permit third parties to offer You subscription or registration-based services through the Services, and the Services may contain links to websites owned by third parties and contain third party advertisements. Such third party sites and advertisements may express opinions, recommendations, or contain other information (&ldquo;Third Party Content&rdquo;). We do not endorse, guarantee or make any representations or warranties regarding Third Party Content, including without limitation, that the Third Party Content is accurate, and the existence of a linked third party site does not mean that We have any affiliation, connection, or endorsement of such website. You acknowledge and agree that We shall not be responsible or liable for the content or conduct of, associated with, or related to any linked third party site, and, accordingly, Your access and use of any linked third party site shall be solely at Your own risk. Your use of third party sites and Third Party Content may be governed by such third party&rsquo;s terms of use or policies. Please review any third party privacy policies before providing Your information on any third party site. We are not liable for or responsible for Your use of third party sites or Third Party Content.</span></p>
                <p style='margin:0in;font-size:16px;font-family:"Times New Roman",serif;text-align:justify;'><span style="font-size:15px;">&nbsp;</span></p>
                <div style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;'>
                    <ol start="4" style="margin-bottom:0in;list-style-type: decimal; padding: 0 1rem;">
                        <li style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;'><span style='font-family:"Times New Roman";font-size:15px;'>HEALTH DISCLAIMERS</span></li>
                    </ol>
                </div>
                <p style='margin:0in;font-size:16px;font-family:"Times New Roman",serif;text-align:justify;'><span style="font-size:15px;">&nbsp;</span></p>
                <p style='margin:0in;font-size:16px;font-family:"Times New Roman",serif;text-align:justify;'><span style="font-size:15px;">Tivity is not a health plan or health insurance company. We do provide our services to health plans and to employers who in turn make our services available to their eligible members and employees. If you have a question about your health benefits or medical benefits, call your health plan or employer for assistance.</span></p>
                <p style='margin:0in;font-size:16px;font-family:"Times New Roman",serif;text-align:justify;'><span style="font-size:15px;">&nbsp;</span></p>
                <p style='margin:0in;font-size:16px;font-family:"Times New Roman",serif;text-align:justify;'><span style="font-size:15px;">The Services do not provide medical advice. Tivity is not a health care provider. We don&rsquo;t offer health care services through Our products or programs. None of the information on the Services, Our programs or Our products is intended to be a substitute for professional medical advice. If You have any question at all about a medical condition, medicine, or prescription, contact Your physician or health care provider. Please don&rsquo;t rely on any of the information on the Services or any of Our programs or products to determine a medical diagnosis or treatment. And, don&rsquo;t delay seeking medical advice for any condition You have or suspect You have. IN AN EMERGENCY, CALL 911 OR YOUR LOCAL EMERGENCY ASSISTANCE NUMBER.</span></p>
                <p style='margin:0in;font-size:16px;font-family:"Times New Roman",serif;text-align:justify;'><span style="font-size:15px;">&nbsp;</span></p>
                <p style='margin:0in;font-size:16px;font-family:"Times New Roman",serif;text-align:justify;'><span style="font-size:15px;">Exercise Programs. Some of the services Tivity offers include exercise or activity programs. If You are engaged in any of those programs, You should always consult with a physician before You begin any exercise activity or program because any exercise activity involves a risk of injury.&nbsp;</span></p>
                <p style='margin-top:0in;margin-right:0in;margin-bottom:0in;margin-left:0in;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-family:"Times New Roman",serif;'>&nbsp;</span></p>
                <div style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;'>
                    <ol start="5" style="margin-bottom:0in;list-style-type: decimal; padding: 0 1rem;">
                        <li style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;'><span style='font-family:"Times New Roman";font-size:15px;'>INTELLECTUAL PROPERTY</span></li>
                    </ol>
                </div>
                <p style='margin:0in;font-size:16px;font-family:"Times New Roman",serif;text-align:justify;'><span style="font-size:15px;">&nbsp;</span></p>
                <p style='margin:0in;font-size:16px;font-family:"Times New Roman",serif;text-align:justify;'><span style="font-size:15px;">We own all right, title and interest in and to the Services and their content including but not limited to the copyrighted material, trademarks, service marks, trade dress, and other proprietary content, including but not limited to text, graphics, images, logos, and software, including all intellectual property rights therein (&ldquo;Content&rdquo;), and the entire selection, coordination, arrangement and &ldquo;look and feel&rdquo; of the Services and the Content are copyrighted as a collective work (collectively, &ldquo;Intellectual Property Rights&rdquo;). We expressly reserve all rights to the Content. Any right You have to use of the Content is specifically and expressly detailed in this Section and Your access to or use of the Content does not give You any right, title, or interest in or to the Content. You may not use any Content for any purpose other than as expressly permitted herein or on the Services. Nothing in or on the Services grants to You, by implication, estoppel, or otherwise, any license or right in or to the Intellectual Property Rights except as set forth herein, without Our express written permission, which may be withheld at Our sole discretion. Tivity Health and the Tivity Health logotype are registered trademarks of Tivity Health, Inc. WholeHealth Living and the WholeHealth Living logotype are trademarks of Tivity Health, Inc.</span></p>
                <p style='margin:0in;font-size:16px;font-family:"Times New Roman",serif;text-align:justify;'><span style="font-size:15px;">&nbsp;</span></p>
                <p style='margin:0in;font-size:16px;font-family:"Times New Roman",serif;text-align:justify;'><span style="font-size:15px;">As long as You comply with these Terms, You may use the Services and You can download and print a small number of copies of the Content on the Services solely for Your personal, noncommercial use. You must keep intact all copyright and other proprietary notices that appear on that Content. You may not copy, modify, distribute, transmit, display, reproduce, publish, license, create derivative works from, link to or frame in another website, use on any other website, sublicense, enable any timesharing or service bureau use of the Services, charge others for use of the Services, or transfer or sell the Services or their Content without Our prior written permission. This prohibition expressly includes, but is not limited to, the practices of &ldquo;screen scraping&rdquo; and &ldquo;data mining.&rdquo; You are prohibited from using the Content for any public or commercial purpose. You may not reverse engineer, disassemble, decompile, or otherwise attempt to derive source code from the Services or their Content. You may not use the Services in any manner inconsistent with these Terms, and You shall comply with these Terms in all respects.&nbsp;</span></p>
                <p style='margin:0in;font-size:16px;font-family:"Times New Roman",serif;text-align:justify;'><span style="font-size:15px;">&nbsp;</span></p>
                <div style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;'>
                    <ol start="6" style="margin-bottom:0in;list-style-type: decimal; padding: 0 1rem;">
                        <li style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;'><span style='font-family:"Times New Roman";font-size:15px;'>DIGITAL MILLENNIUM COPYRIGHT ACT</span></li>
                    </ol>
                </div>
                <p style='margin:0in;font-size:16px;font-family:"Times New Roman",serif;text-align:justify;'><span style="font-size:15px;">&nbsp;</span></p>
                <p style='margin:0in;font-size:16px;font-family:"Times New Roman",serif;text-align:justify;'><span style="font-size:15px;">In accordance with the Digital Millennium Copyright Act (&ldquo;DMCA&rdquo;) http://lcweb.loc.gov/copyright/, We have designated an agent to receive notifications of alleged copyright infringement associated with the Services. We will use commercially reasonable efforts to investigate any alleged infringement and take appropriate action in accordance with the DMCA. If You believe that a copyrighted work is being infringed by the Content, please notify Our copyright agent at legal@tivityhealth.com or to Our address for notices at the end of these Terms. In Your notice of alleged copyright infringement, include the following:</span></p>
                <ul style="list-style-type: disc;margin-left:0in;">
                    <li><span style="font-size:11.0pt;">A physical or electronic signature of a person authorized to act on behalf of the owner of the copyright interest that is claimed to have been infringed;</span></li>
                    <li><span style="font-size:11.0pt;">Identification of the copyrighted work alleged to have been infringed;</span></li>
                    <li><span style="font-size:11.0pt;">A description of the material that is claimed to be infringing and information sufficient to locate the material on the Services;</span></li>
                    <li><span style="font-size:11.0pt;">Information sufficient to contact the complaining party, such as a physical address, telephone number, and, if available, an email address;</span></li>
                    <li><span style="font-size:11.0pt;">A statement that the complaining party has a good faith belief that the use of the material in the manner complained of is not authorized by the copyright owner, its agent, or the law; and</span></li>
                    <li><span style="font-size:11.0pt;">A statement that the information in the notification is accurate and, under penalty of perjury, that the complaining party is authorized to act on the copyright owner&rsquo;s behalf.</span></li>
                </ul>
                <p style='margin:0in;font-size:16px;font-family:"Times New Roman",serif;text-align:justify;'><span style="font-size:15px;">&nbsp;</span></p>
                <div style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;'>
                    <ol start="7" style="margin-bottom:0in;list-style-type: decimal; padding: 0 1rem;">
                        <li style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;'><span style='font-family:"Times New Roman";font-size:15px;'>DISCLAIMER ON WARRANTIES AND LIMITATION OF LIABILITY</span></li>
                    </ol>
                </div>
                <p style='margin:0in;font-size:16px;font-family:"Times New Roman",serif;text-align:justify;'><span style="font-size:15px;">&nbsp;</span></p>
                <p style='margin:0in;font-size:16px;font-family:"Times New Roman",serif;text-align:justify;'><em><span style="font-size:15px;">Disclaimer of Warranties</span></em><span style="font-size:15px;">. You understand that the Services may contain errors, &ldquo;bugs,&rdquo; and other problems, which may result in system failure or failure in the use of the Services, and any services offered through the Services, or loss of data or access by third parties to personally identifiable information provided through the Services. The Services may not be continuously available due to maintenance or due to computer or device problems, disruption in Internet service or other unforeseen circumstances beyond Our control. TO THE FULLEST EXTENT PERMISSIBLE UNDER APPLICABLE LAW, THIS SITE AND ITS CONTENT ARE PROVIDED &ldquo;AS IS&rdquo; AND &ldquo;WITH ALL FAULTS.&rdquo; We do not make any representations or warranties with respect to the Services or THEIR Contents and to the fullest extent permitted by law disclaim all warranties, express or implied, including without limitation, warranties of merchantability, completeness, timeliness, correctness, title, security, non-infringement, and fitness for a particular purpose, use or application. We HEREBY DISCLAIM ANY AND ALL LIABILITY RELATED TO THE USE OR TRANSMISSION OF PERSONALLY IDENTIFIABLE INFORMATION IN CONNECTION WITH THIS SITE OR SERVICES PROVIDED ON THIS SITE.</span></p>
                <p style='margin:0in;font-size:16px;font-family:"Times New Roman",serif;text-align:justify;'><span style="font-size:15px;">&nbsp;</span></p>
                <p style='margin:0in;font-size:16px;font-family:"Times New Roman",serif;text-align:justify;'><em><span style="font-size:15px;">Limitation of Liability</span></em><span style="font-size:15px;">. By using the Services, You agree that You are voluntarily and unequivocally waiving any potential claim against Us for liability to You. We are not liable for damage or injury to persons or property arising from any use of any product, information, idea, or instruction on the Services or contained in the other materials provided to You. SPECIFICALLY, IN &nbsp;NO EVENT SHALL WE BE LIABLE FOR ANY DAMAGES, CLAIMS, DEMANDS OR CAUSES OF ACTION, DIRECT OR INDIRECT, SPECIAL, INCIDENTAL, CONSEQUENTIAL, EXEMPLARY, OR PUNITIVE, ARISING OUT OF OR IN CONNECTION WITH YOUR USE OF THIS SITE OR ITS CONTENT, INCLUDING WITHOUT LIMITATION, ANY DAMAGES RESULTING FROM LOSS OF USE, PROFITS, REVENUE, DATA, GOODWILL OR USE, INCURRED BY YOU OR ANY THIRD PARTY, WHETHER IN AN ACTION IN CONTRACT OR TORT (INCLUDING NEGLIGENCE AND STRICT LIABILITY), EVEN IF WE HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. &nbsp;SOME JURISDICTIONS DO NOT ALLOW CERTAIN LIMITATIONS OR EXCLUSIONS OF LIABILITY IN SOME CIRCUMSTANCES. CONSEQUENTLY, SOME OF THE FOREGOING LIMITATIONS MAY NOT APPLY TO YOU.</span></p>
                <p style='margin:0in;font-size:16px;font-family:"Times New Roman",serif;text-align:justify;'><span style="font-size:15px;">&nbsp;</span></p>
                <p style='margin:0in;font-size:16px;font-family:"Times New Roman",serif;text-align:justify;'><span style="font-size:15px;">IF ANY EXCLUSION, DISCLAIMER OR OTHER PROVISION CONTAINED IN THIS AGREEMENT IS HELD TO BE INVALID FOR ANY REASON BY A COURT OF COMPETENT JURISDICTION, AND WE, OR ONE OF OUR AFFILIATES, OFFICERS, DIRECTORS, AGENTS OR EMPLOYEES BECOMES LIABLE FOR LOSS OR DAMAGE THAT COULD OTHERWISE BE LIMITED, SUCH LIABILITY WHETHER IN CONTRACT, TORT OR OTHERWISE WILL NOT EXCEED IN THE AGGREGATE THE GREATER OF THE AMOUNT ACTUALLY PAID BY YOU TO US (IF ANY) OR ONE HUNDRED DOLLARS ($100.00 USD). IF YOU ARE DISSATISFIED WITH ANY PORTION OF THIS SITE, OR WITH ANY PORTION OF THESE TERMS, YOUR SOLE AND EXCLUSIVE REMEDY IS TO DISCONTINUE USING THIS SITE.</span></p>
                <p style='margin:0in;font-size:16px;font-family:"Times New Roman",serif;text-align:justify;'><span style="font-size:15px;">&nbsp;</span></p>
                <p style='margin:0in;font-size:16px;font-family:"Times New Roman",serif;text-align:justify;'><em><span style="font-size:15px;">Limitation on Actions</span></em><span style="font-size:15px;">. Notwithstanding any statute of limitations or other law to the contrary, any claim or cause of action arising out of Your use of the Services or their Content must be filed within one (1) year after such claim or cause of action arose or it shall forever be barred. Our failure to enforce or exercise any provision of these Terms or any other right related to the Services or their Content, within one (1) year shall not constitute a waiver of that right or provision.</span></p>
                <p style='margin:0in;font-size:16px;font-family:"Times New Roman",serif;text-align:justify;'><span style="font-size:15px;">&nbsp;</span></p>
                <div style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;'>
                    <ol start="8" style="margin-bottom:0in;list-style-type: decimal; padding: 0 1rem;">
                        <li style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;'><span style='font-family:"Times New Roman";font-size:15px;'>INDEMNIFICATION</span></li>
                    </ol>
                </div>
                <p style='margin:0in;font-size:16px;font-family:"Times New Roman",serif;text-align:justify;'><span style="font-size:15px;">&nbsp;</span></p>
                <p style='margin:0in;font-size:16px;font-family:"Times New Roman",serif;text-align:justify;'><span style="font-size:15px;">You will indemnify, defend and hold Us harmless against any and all claims or causes of action brought by third parties, including but not limited to, in connection with Your negligence or willful misconduct; Your breach of these Terms; Your violation of any federal, state or local laws, rules or regulations including those governing privacy, security or data protection; or Your use of the Services or their Content that infringes any intellectual property or proprietary rights.</span></p>
                <p style='margin:0in;font-size:16px;font-family:"Times New Roman",serif;text-align:justify;'><span style="font-size:15px;">&nbsp;</span></p>
                <div style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;'>
                    <ol start="9" style="margin-bottom:0in;list-style-type: decimal; padding: 0 1rem;">
                        <li style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;'><span style='font-family:"Times New Roman";font-size:15px;'>DISPUTE RESOLUTION</span></li>
                    </ol>
                </div>
                <p style='margin:0in;font-size:16px;font-family:"Times New Roman",serif;text-align:justify;'><span style="font-size:15px;">&nbsp;</span></p>
                <p style='margin:0in;font-size:16px;font-family:"Times New Roman",serif;text-align:justify;'><span style="font-size:15px;">Any dispute arising out of or related to Your use of or access to the Services shall be resolved exclusively by confidential binding arbitration except We may seek injunctive or other appropriate relief to the extent We believe You have violated or threatened to violate Our intellectual property rights. The arbitration shall be conducted by one neutral arbitrator in Nashville, Tennessee under American Arbitration Association rules then in effect. No claims of any other parties may be joined or otherwise combined in the arbitration proceeding. Each party shall bear its own attorneys&rsquo; fees without regard to which party prevails. The arbitrator&rsquo;s decision will be final and binding. The award of the arbitrator may be enforced in any court of competent jurisdiction. Each party consents to (i) the exclusive jurisdiction of the state or federal courts located in Nashville, Tennessee for any action (1) to compel arbitration, (2) to enforce any award of the arbitrator, or (3) service of process in any such action by registered mail or any other means provided by law. EACH OF YOU AND WE EXPRESSLY WAIVES ANY RIGHT TO A BENCH TRIAL, JURY TRIAL AND TO PARTICIPATE IN A CLASS ACTION OR ANY OTHER COLLECTIVE OR REPRESENTATIVE PROCEEDING.</span></p>
                <p style='margin:0in;font-size:16px;font-family:"Times New Roman",serif;text-align:justify;'><span style="font-size:15px;">&nbsp;</span></p>
                <div style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;'>
                    <ol start="10" style="margin-bottom:0in;list-style-type: decimal; padding: 0 1rem;">
                        <li style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;'><span style='font-family:"Times New Roman";font-size:15px;'>IMPORTANT DISCLOSURES</span></li>
                    </ol>
                </div>
                <p style='margin:0in;font-size:16px;font-family:"Times New Roman",serif;text-align:justify;'><span style="font-size:15px;">&nbsp;</span></p>
                <p style='margin:0in;font-size:16px;font-family:"Times New Roman",serif;text-align:justify;'><span style="font-size:15px;">AS PART OF THE CHOICES PROGRAM (THE &ldquo;CHOICES PROGRAM&rdquo;), MEMBERS ARE PROVIDED WITH ACCESS TO A NETWORK OF PARTICIPATING THIRD-PARTY PROVIDERS WHO OFFER DISCOUNTED COMPLEMENTARY AND ALTERNATIVE MEDICINE SERVICES INCLUDING, BUT NOT LIMITED TO, CHIROPRACTIC, ACUPUNCTURE, MASSAGE/BODY WORK SERVICES (&ldquo;CAM SERVICES&rdquo;). THE CHOICES PROGRAM IS NOT INSURANCE. DISCOUNTS VARY PER CAM SERVICE PROVIDER. THE CHOICES PROGRAM MEMBER MUST PAY FOR DISCOUNTED CAM SERVICES, BUT WILL RECEIVE A DISCOUNT FROM THE PARTICIPATING CAM SERVICE PROVIDERS. THE CAM SERVICES NETWORK IS NOT CURRENTLY AVAILABLE TO RESIDENTS OF CALIFORNIA, CONNECTICUT, DELAWARE, INDIANA, LOUISIANA, MONTANA, NEBRASKA, NEW HAMPSHIRE, NEVADA, OKLAHOMA, OREGON, RHODE ISLAND, SOUTH CAROLINA, SOUTH DAKOTA, UTAH, WASHINGTON, OR WEST VIRGINIA.&nbsp;</span></p>
                <p style='margin:0in;font-size:16px;font-family:"Times New Roman",serif;text-align:justify;'><span style="font-size:15px;">&nbsp;</span></p>
                <div style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;'>
                    <ol start="11" style="margin-bottom:0in;list-style-type: decimal; padding: 0 1rem;">
                        <li style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;'><span style='font-family:"Times New Roman";font-size:15px;'>GENERAL TERMS</span></li>
                    </ol>
                </div>
                <p style='margin:0in;font-size:16px;font-family:"Times New Roman",serif;text-align:justify;'><span style="font-size:15px;">&nbsp;</span></p>
                <p style='margin:0in;font-size:16px;font-family:"Times New Roman",serif;text-align:justify;'><em><span style="font-size:15px;">Governing Law and Venue</span></em><span style="font-size:15px;">. These Terms and all other matters arising from Your use of the Services or their Content shall be governed by the laws of the State of Tennessee, without regard to its choice of law provisions. Should binding arbitration be deemed invalid or otherwise unenforceable for any reason, the Dispute Resolution provision of these Terms shall be severed and the parties expressly consent to and agree that the exclusive jurisdiction and venue for any claims will be in state or federal courts in Nashville, Tennessee.&nbsp;</span></p>
                <p style='margin:0in;font-size:16px;font-family:"Times New Roman",serif;text-align:justify;'><span style="font-size:15px;">&nbsp;</span></p>
                <p style='margin:0in;font-size:16px;font-family:"Times New Roman",serif;text-align:justify;'><em><span style="font-size:15px;">Severability</span></em><span style="font-size:15px;">. If any part of these Terms is held by a court of competent jurisdiction to be invalid or unenforceable, that part will be enforced to the maximum extent permitted by law and the remainder of these Terms will remain fully in force.</span></p>
                <p style='margin:0in;font-size:16px;font-family:"Times New Roman",serif;text-align:justify;'><span style="font-size:15px;">&nbsp;</span></p>
                <p style='margin:0in;font-size:16px;font-family:"Times New Roman",serif;text-align:justify;'><em><span style="font-size:15px;">Waiver</span></em><span style="font-size:15px;">. Our failure to act with respect to a breach by any visitor using the Services does not constitute a waiver of Our right to act with respect to subsequent or similar breaches.&nbsp;</span></p>
                <p style='margin:0in;font-size:16px;font-family:"Times New Roman",serif;text-align:justify;'><span style="font-size:15px;">&nbsp;</span></p>
                <p style='margin:0in;font-size:16px;font-family:"Times New Roman",serif;text-align:justify;'><em><span style="font-size:15px;">Assignment</span></em><span style="font-size:15px;">. We may assign Our rights and duties under these Terms without notice to You. You may not assign these Terms, in whole or in part, without Our prior written consent, and any assignment in contravention of the foregoing shall be null and void.&nbsp;</span></p>
                <p style='margin:0in;font-size:16px;font-family:"Times New Roman",serif;text-align:justify;'><span style="font-size:15px;">&nbsp;</span></p>
                <p style='margin:0in;font-size:16px;font-family:"Times New Roman",serif;text-align:justify;'><em><span style="font-size:15px;">Termination of Access</span></em><span style="font-size:15px;">. We reserve the right to modify or terminate Your access to the Services (or portions of the Services) at any time, temporarily or permanently, with or without notice to you, and is not obligated to support or update the Services. Sections 4 &ndash; 11 of these Terms shall survive any termination.&nbsp;</span></p>
                <p style='margin:0in;font-size:16px;font-family:"Times New Roman",serif;text-align:justify;'><span style="font-size:15px;">&nbsp;</span></p>
                <p style='margin:0in;font-size:16px;font-family:"Times New Roman",serif;text-align:justify;'><em><span style="font-size:15px;">Notices</span></em><span style="font-size:15px;">. Any notice to Us shall be given in writing and sent by certified and registered mail to 701 Cool Springs Blvd., Franklin, Tennessee 37067, Attn: Legal.&nbsp;</span></p>
                <p style='margin:0in;font-size:16px;font-family:"Times New Roman",serif;text-align:justify;'><span style="font-size:15px;">&nbsp;</span></p>
                <p style='margin:0in;font-size:16px;font-family:"Times New Roman",serif;text-align:justify;'><em><span style="font-size:15px;">Agreement</span></em><span style="font-size:15px;">. These Terms, as revised from time to time by Us, constitute the entire agreement between the parties with regard to the subject matter in these Terms and supersede all prior understandings and agreements, whether written or oral, as to such subject matter. You hereby acknowledge (a) that You have read and understood these Terms, and (b) that these Terms have the same force and effect as a signed agreement.</span></p>
                <p style='margin:0in;font-size:16px;font-family:"Times New Roman",serif;text-align:justify;'><span style="font-size:15px;">&nbsp;</span></p>
                `}}
                >
                </div>
            </section>
        </Layout>
    );
};

export default TermsOfUse;