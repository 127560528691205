import { SortOptions } from "../models/sortOptions";

export default class ProductSearchDto {
    // eslint-disable-next-line max-len
    constructor(productTypeId, selectedPageNumber, pageLimit, selectedSubCategories = [], searchRadius, sortBy) {
        this.productTypeId = productTypeId;
        this.clientId = parseInt(localStorage.getItem("choices-clientId")) || 0;
        this.categoryId = 0;
        this.subCategoryId = selectedSubCategories.map(subCategory => subCategory.id);
        this.searchRadius = searchRadius;
        this.pagination = {
            page: selectedPageNumber,
            pageSize: pageLimit
        };
        this.sortParam = sortBy || SortOptions[0];
        this.geoLocation = {
            latitude: parseFloat(localStorage.getItem("choices-latitude")) || 0,
            longitude: parseFloat(localStorage.getItem("choices-longitude")) || 0
        };
        this.userState = localStorage.getItem("choices-state");
        this.userEmailAddress = localStorage.getItem("choices-email");
        this.whlServerUrl = window.origin;
    }
}
