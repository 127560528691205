import React, { useEffect } from 'react';
import { Spinner } from 'react-bootstrap';
import { useAuthLogic, TokenContext } from '../../hooks/useAuthLogic';
import { useNavigate } from 'react-router-dom';

export const OAuthRedirect = ({ children }) => {
    const { token, isAuthenticated } = useAuthLogic();
    const navigate = useNavigate();

    useEffect(() => {
        if (isAuthenticated) {
            navigate('/');
        }
    }, [isAuthenticated, navigate]);

    if (isAuthenticated === undefined) {
        return <Spinner animation="border" />;
    }

    return <TokenContext.Provider value={token}>{children}</TokenContext.Provider>;
};
